nav, footer, body {
  background-color: #10337c;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

#signIn {
  padding: 1rem 0;
  .box {
    .responsive with-social-login {
      display: none;
    }
  }
}

.gigya-layout-cell.with-social-login {
  min-width: 0;
  width: 0px !important;
}
.gigya-screen.landscape .gigya-layout-row.with-divider>.gigya-layout-cell.with-divider {
  margin-top: 41px;
  width: 0px !important;
  background-repeat: repeat-y;
  background: none!important;
}

div.gigya-screen.landscape {
  padding-left: 0px !important;
  padding-right: 10px !important;
  width: 315px !important;
  padding-top: 0px !important;
}

.gigya-login-form {
  .with-site-login {
    div {
      label {
        span {
          color: #fafafa !important;
        }
      }
    }
  }
}

.gigya-reset-password-form{
  div {
    div {
      .gigya-image {
        display: none !important;
      }
    }
  }
}

.gigya-screen {
  padding-bottom: 15px !important;
}

.gigya-label {
  color: #242424 !important;
}

.gigya-screen.portrait {
  width: 310px !important;
  font-size: 15px;
  padding-left: 0px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
}
#form .gigya-composite-control-password {
  color: #fafafa !important;
  font-weight: 600 !important;
  margin-bottom: 15px !important;
}

#gigya-complete-registration-screen {
  color: #fafafa !important;
}

#change-password .gigya-composite-control-password {
  color: #242424 !important;
  font-weight: 600 !important;
  margin-bottom: 15px !important;
}

.regulamento {
  p {
    a {
      color: rgb(250, 205, 26) !important;
    }
  }
}

/** font-weight */
.b-1 {
  font-weight: 100 !important;
}

.b-2 {
  font-weight: 200 !important;
}

.b-3 {
  font-weight: 300 !important;
}

.b-4 {
  font-weight: 400 !important;
}

.b-5 {
  font-weight: 500 !important;
}

.b-6 {
  font-weight: 600 !important;
}

.b-7 {
  font-weight: 700 !important;
}

.b-8 {
  font-weight: 800 !important;
}

.b-9 {
  font-weight: 900 !important;
}

// /** font-size */
// .f-5 {
//   font-size: .5rem !important;
// }

// .f-6 {
//   font-size: .6rem !important;
// }

// .f-7 {
//   font-size: .7rem !important;
// }

// .f-8 {
//   font-size: .8rem !important;
// }

// .f-9 {
//   font-size: .9rem !important;
// }

// /** Color Text */
// .white-text {
//   color: #fff !important;
// }

// .blue-text {
//   color: #347597 !important;
// }

// .amarelo-text {
//   color: #fe8d15 !important;
// }

// .purple-text {
//   color: #674d7c !important;
// }

// .red-text {
//   color: #b11414 !important;
// }

// /** Background */
// .bg-blue {
//   background: #37a0cd !important;
// }

// .bg-blue-1 {
//   background: #347597 !important;
// }

// .bg-amarelo {
//   background: #fe8d15 !important;
// }

// .bg-white {
//   background: #fff !important;
// }


// .text-capitalize {
//   text-transform: capitalize !important;
// }

// .container {
//   width: 100%;
//   margin: 0 auto;
// }

// .swiper-button-next, .swiper-button-prev {
//   display: none !important;
// }

// svg{ color: #fff !important;}

// @media(min-width: 576px) {
//   .container { max-width: 540px; }
// }

// @media(min-width: 768px) {
//   .container { max-width: 720px; }
// }

// @media(min-width: 992px) {
//   .container { max-width: 960px; }
//   .swiper-button-next, .swiper-button-prev {
//     display: block !important;
//   }
// }

// @media(min-width: 1200px) {
//   .container { max-width: 1200px; }
// }


// /** Regulamento */
// //** Regulamento
// .conteudo {
//   div{
//     margin-bottom: 1rem;
//     color: #000 !important;
//     h3{
//       font-size: 26px;
//       font-weight: 800;
//       text-align: center;
//     }
//     h4{ font-size: 22px; }
//     p{
//       text-align: justify;
//     }
//   }
// }

// .selectable-value{
//   color: #10337c !important;
// }




// // LP
// nav, footer {
//   background-color: #004c98;
// }
// .navbar-dark .navbar-nav .nav-link {
//   color: rgba(255, 255, 255, 1);
// }
// #personagem{
//   .lead {
//     .titulo {
//       font-weight: 500;
//   }
//   .subtitulo {
//       font-weight: 500;
//       span {
//           display: block;
//           color: #ffd001;
//           font-size: 2rem;
//           font-weight: 700;
//       }
//   }
//   }
// }
// #vantagens {
//   .titulo {
//     text-align: center;
//     font-weight: 600;
//     font-size: 2rem;
//     margin-bottom: 2rem;
//     span {
//       display: block;
//       b {
//         font-weight: 900;
//         color: red;
//       }
//     }
//   }
// }
// #aplicativo{
//   .text-novo {
//     color: #ffd001;
//     font-weight: 600;
//     font-size: 1.2rem;
//     span {
//       color: #fff;
//     }
//   }
// }

// @media(min-width: 1200px) {
//   #aplicativo{
//     .text-novo {
//       font-size: .8rem;
//     }
//   }
// }
