/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

.fullWidthModal{
  @media(min-width: 1200px) {
    --width: 70vw;
  }
}


// :root, root[mode="md"], root[mode="ios"] {
//     .register-modal {
//         --backdrop-opacity: .9;
//         @media(min-width: 768px) {
//             --width: 60vh;
//             --height: 90vh;
//         }
//         @media(min-width: 992px) {
//             --width: 900px;
//         }
//         @media(min-width: 1366px) {
//             --width: 1200px;
//         }
//     };
//   }



// @media(min-width: 768px) {
//     .senha-css {
//         --backdrop-opacity: .9 !important;
//     }
// }

// @media(min-width: 992px) {
//     .senha-css {
//         --backdrop-opacity: .9 !important;
//         .modal-wrapper {
//             --width: 768px !important;
//         }
//     }
// }

// @media(min-width: 1200px) {
//     .senha-css {
//         --backdrop-opacity: .9 !important;
//         .modal-wrapper {
//             --width:  95vw !important;
//             --height: 95vh !important;
//         }
//     }
//     .detail-css{
//         --backdrop-opacity: .9 !important;
//         .modal-wrapper {
//             --width: 900px !important;
//             --height: 80%;
//         }
//     }
// }



// GLOBAL
ion-content {
  --color: #383838;
  --background: #f1f1f1;
}

#menu {
  --background: none;
  ion-label {
    h2, h3, h4, h1, p {
      color: var(--clube-primary);
      span {
        font-weight: 700;
      }
    }
  }
}

.bg-grey-300 {
  background: #efefef !important;
}

@media only screen and (min-width: 768px) {
  .frame-large {
    --backdrop-opacity: .9 !important;
    --width:  80vw !important;
    --height: 80vh !important;
  }
}

.custom-icon-notfound {color: red}